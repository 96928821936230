@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.template-card {
  overflow: hidden;
  background-color: white !important;
  color: rgba(Black, 0.87) !important;

  ::v-deep &.v-card.v-sheet {
    box-shadow: none;
    border: 1px solid var(--color-border-theme);
  }

  &__text {
    border-top: 1px solid var(--color-border-theme);

    @media ($wx-sm-min) {
      height: 12em;
    }
    @media ($wx-md-min) {
      height: 17em;
    }
    @media ($wx-lg-min) {
      height: 12em;
    }
    @media ($wx-xl-min) {
      height: 8em;
    }

    &__title {
      margin-bottom: 0.4rem;
      line-height: 1.2;
      word-break: keep-all;
    }
    &__subtitle {
      line-height: 1.4;
      color: rgba(Black, 0.87) !important;
    }
  }
}
.explore-dialog {
  position: relative;
  background: var(--color-base-background) !important;
  color: var(--color-text-theme) !important;

  .close-btn {
    position: absolute;
    z-index: 1;
    top: calc(var(--dialog-close-offset) / 2);
    right: calc(var(--dialog-close-offset) / 2);
  }

  &__subtitle {
    color: var(--color-text-theme) !important;
  }
  &__figure {
    position: relative;
    z-index: 0;
    height: 70vh;

    ::v-deep &.v-card__text {
      padding-bottom: 0;
    }

    ::v-deep .v-image {
      padding-inline: var(--grid-gutter);
      border: solid 1px var(--color-border-theme);
      border-bottom-width: 0;
    }

    &::after {
      content: "";
      display: block;
      position: sticky;
      bottom: 0;
      z-index: 0;
      width: 100%;
      height: 0;
      border-top: solid 1px var(--color-border-theme);
    }
  }
}

#custom-disabled.v-btn--disabled {
  background-color: gray !important;
  color: white !important;
}
